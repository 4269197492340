// Placeholder component till the real Federated search loads
<template>
  <div class="search-container">
    <input
      class="search-input"
      :placeholder="$t('search.algoliaAutocomplete.placeholder')"
    />
    <div class="search-icon">
      <svg
        class="aa-SubmitIcon"
        viewBox="0 0 24 24"
        width="20"
        height="20"
        fill="currentColor"
      >
        <path
          d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"
        ></path>
      </svg>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.search-container {
  @apply flex;
  @apply rounded-full;
  @apply bg-white;
}

.search-icon {
  @apply bg-white sm:bg-primary sm:text-white;
  @apply rounded-l-full sm:rounded-r-full sm:mr-1;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-top: 0.198rem;
  margin-bottom: 0.202rem;
  @apply pl-3 sm:pr-3 sm:mr-1;
}

.search-input {
  @apply p-3 sm:rounded-l-full sm:pl-8;
  @apply grow;

  &:focus-visible {
    outline: none;
  }
}

.fullwidth {
  &.search-container {
    @apply grow;
    @apply border border-gray-100 rounded-full;
    @apply mx-4 sm:mx-8;
  }
  .search-icon {
    @apply bg-white text-gray-500;
    @apply my-0 order-first;
  }

  .search-input {
    @apply p-1.5 sm:ml-3;
  }
}
</style>
